.articleContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  // max-width: 960px;
  padding: 20px;
}

.textContainer {
  max-width: 960px;
  align-self: center;
  margin: 40px 0;
}

.priceCardsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.priceCard {
  color: #2a2972;
  border: 4px solid #2a2972;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.27);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 720px;
  min-width: 320px;
  // width: 320px;
}

.priceCardSection {
  margin: 16px;
  align-self: center;
}

.clipCard {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  padding: 20px;
  background-color: #2a2972;
  color: #F1E3F3;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(#000000, 0.30);
  width: 300px;
}

.clipPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 20px 8px 0 8px;
}

@media screen and (min-width: 1024px) {
  .priceCardsContainer {
    flex-direction: row;
  }

  .priceCard {
    margin: 20px 8px;
  }
}

.WaveSection {
  position: relative;
  width: 100%;
  padding: 40px 20px;
  background-color: #2a2972;

  margin: 40px 0;

  &:before {
    position: absolute;
    content: url(../../assets/wave.svg);
    // width: 100%;
    transform: translateY(-129.3%);
    left: 0;
  }
  &:after {
    position: absolute;
    content: url(../../assets/wave.svg);
    // width: 100%;
    transform: translateY(29.3%) rotate(180deg);
    right: 0;
  }
}

@media screen and (min-width: 768px) {
  .WaveSection {
    padding: 160px 40px;
    margin: 200px 0;

    &:before,
    &:after {
      content: url(../../assets/SVGs/waveXL.svg);
    }

    &:before {
      transform: translateY(-130%);
    }

    &:after {
      transform: translateY(30%) rotate(180deg);
    }
  }
}

@media screen and (min-width: 1024px) {
  .WaveSection {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    &:before {
      transform: translateY(-480px);
    }

    &:after {
      transform: translateY(480px) rotate(180deg);
    }
  }
}

@media screen and (min-width: 1366px) {
  .WaveSection {
    &:before,
    &:after {
      content: url(../../assets/SVGs/waveXL.svg);
    }

    &:before {
      transform: translateY(-135%);
    }

    &:after {
      transform: translateY(135%) rotate(180deg);
    }
  }
}

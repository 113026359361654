.HeroText {
  padding: 20px;
  max-width: 870px;
}

@media screen and (min-width: 768px) {
  .HeroText {
    display: flex;
    flex-direction: column;
    place-content: center center;
    align-self: center;
    [class*="Title"]{
      white-space: nowrap;
    transform: translateX(-40px);

    }
  }
}

@media screen and (min-width: 1440px){
   .HeroText{
     align-self: flex-start;
   }
}


nav {
  align-self: center;
  ul {
    padding: 0;
    margin: 0 0 0 12px;
    list-style-type: none;
    display: flex;

    li {
      a {
        font-size: 16px;
        padding: 8px;
        text-decoration: none;
        color: #000;
        user-select: none;

        &[class*="active"]{
          font-weight: bold;
          color: #2A2972;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.disabled {
  display: none;
  text-decoration: line-through;
}
.listContainer{
  margin: 40px 0;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .listItem {
    margin: 12px 0;
  
    * {
      padding: 8px 0;
      color: #2A2972;
    }

    a {
      padding: 8px 0;
      text-transform: uppercase;
      text-decoration: none;
      transition: 250ms;
      user-select: none;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  &_lightText {
    color: #F1E3F3;

    .listItem {
      * {
        color: #F1E3F3;
      }
    }
  }
}


@media screen and (min-width: 768px){
   .listContainer:not(:last-of-type) {
     margin-right: 40px;
   }
}


@media screen and (min-width: 1366px){
   .listContainer:not(:last-of-type) {
     margin-right: 80px;
   }
}



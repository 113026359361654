.Button {
  padding: 12px 44px;
  font-size: 20px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #2a2972;
  background-color: transparent;
  transition: 250ms;
  border-radius: 6px;
  border: none;
  margin: 8px;
  cursor: pointer;
  text-decoration: none;
}

.Primary {
  background-color: #60afff;
  box-shadow: 0px 3px 6px #0000002e;

  &:hover {
    background: #5ba5f2;
    box-shadow: 0px 3px 12px #00000047;
  }
}

.Text {
  &:hover {
    background: #60afff33;
    box-shadow: 0px 3px 6px #0000002e;
  }
}

.Card {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: white;
  border-radius: 16px;
  // width: 80vw;
  // min-width: 280px;
  // max-width: 480px;
  box-shadow: 0 6px 18px #32323232;
  height: 100%;
  margin: 16px 0;
  // margin-right: 20px;  
}

.imgContainer{
  height: 200px;
  margin-bottom: 40px;
  border-radius: 16px 16px 0 0;
  // background: hotpink;
  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Text{
  padding: 0 16px;
}

.buttons {
  margin-top: 20px;
  text-align: right;
}
.image {
  height: 280px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/Images/contact.jpg");
}

.text {
  padding: 40px 20px;
}

.contactLink {
  color: #2a2972;
  text-decoration: none;
  transition: 250ms;

  &:hover {
    color: #2a2972b7;
  }
}

@media screen and (min-width: 768px) {
  .text {
    padding: 40px 60px;

    [class*="Heading"] {
      font-size: 56px;
    }
    [class*="SubHeading"] {
      font-size: 33px;
    }

    .links {
      display: flex;
      flex-flow: row nowrap;
      [class*="contactLink"] {
        font-size: 20px;
        margin-right: 40px;
      }
    }
  }
}

@media screen and (min-width: 1366px) {
  .contact {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    width: 50%;
  }

  [class*="Heading"] {
    width: 100%;
  }

  .image {
    width: 50%;
    height: 640px;
  }
  
  .links {
    display: flex;
    flex-flow: row nowrap;
    [class*="contactLink"] {
      font-size: 20px;
      margin-right: 40px;
    }
  }
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

html,
body,
img {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

html {
  background-color: #f5f5f5;
}

html, body{
  overflow-x: hidden;
}

main {
  display: flex;
  flex-direction: column;
}

@mixin angleBaseRules {
  content: "";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  border-color: #2a2972;
  border-style: solid;
  border-width: 0;
}

.graphics {
  position: relative;
  display: block;
  &__angle {
    &Top {
      &Left {
        &:before {
          @include angleBaseRules;
          top: -8px;
          left: -16px;
          border-left-width: 4px;
          border-top-width: 4px;
        }
      }
      &Right {
        &:after {
          @include angleBaseRules;
          top: 0;
          right: 0;
          border-right-width: 4px;
          border-top-width: 4px;
        }
      }
    }
    &Bottom {
      &Left {
        &:before {
          @include angleBaseRules;
          bottom: 0;
          left: 0;
          border-left-width: 4px;
          border-bottom-width: 4px;
        }
      }
      &Right {
        &:after {
          @include angleBaseRules;
          bottom: -8px;
          right: -8px;
          border-right-width: 4px;
          border-bottom-width: 4px;
        }
        &LightColor {
          &::after {
            @include angleBaseRules;
            border-color: #f1e3f3 !important;
            bottom: -16px;
            right: -16px;
            border-right-width: 4px;
            border-bottom-width: 4px;
          }
        }
      }
    }
  }
}

.newsRow {
  padding: 1em;
  background-color: #FF4F4F;

  a {
    color: white;
    margin-left: 4pt;
  }
}

.Header {
  background: white;
  
  // height: 40px;
  padding: 12px;
  display: flex;
  flex-direction: row; 
  align-content: center;
//   justify-content: space-between;
 }

.Logo{
  width: 40px;
  max-width: 40px;
  margin: 0;
}
.Paragraph {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #2a2972;
  margin: 20px 0;
  max-width: 720px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;

    &:first-of-type {
      margin-top: 40px;
    }
  }
}

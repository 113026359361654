.reviewsContainer {
  background-color: #2a2972;
  padding: 40px 0;

  @media screen and (min-width: 768px) {
    position: relative;
    padding: 80px 0;

    &::after,
    &::before {
      content: '"';
      display: block;
      font-size: 366px;
      line-height: 128px;
      position: absolute;
      color: #f1e3f3;
      z-index: 3;
      opacity: 0.2;
    }

    &::before {
      top: 24%;
      left: 0;
    }

    &::after {
      bottom: -16%;
      right: 0;
      // transform: rotate(180deg);
    }
  }
}

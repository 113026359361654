.heroSection {
  padding: 20px;
}

.buttonsHero {
  display: flex;
  flex-direction: column;
  place-content: center center;
  margin-top: 40px;
}

.mbsSytemSection {
  display: flex;
  flex-direction: column;
  margin: 80px auto;
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;


  .mbsIconsImg {
    margin: 20px;
    max-width: 612px;
    align-self: center;
  }

  .mbsContent {
    display: flex;
    flex-direction: column;
    .mbsText {
      max-width: 720px;
      display: flex;
      flex-direction: column;
      align-self: center;
      place-self: center center;
      margin: 0 20px;
    }

    .mbsButton {
      margin: 20px 0;
      align-self: center;
    }
  }
}

.mbsImages {
  display: none;
}

@media (min-width: 768px) {
  .heroSection {
    padding: 160px 40px;
  }

  .heroContent {
    display: flex;
    flex-direction: column;
  }

  .buttonsHero {
    flex-direction: row-reverse;
    justify-content: center;
  }

  .mbsSytemSection {
    margin-bottom: 0;
    .mbsButton {
      align-self: flex-end;
    }
  }

  .collegueImage {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(../../assets/Images/staff/IMG_0095.JPG);
    height: 360px;
  }

  .graphicsHolder {
    overflow-x: hidden;
    position: relative;
    height: 200px;
    width: 100vw;
    transform: translateY(-20%);
    &:before,
    &:after {
      position: absolute;
      // transform: translateY(100%)
    }

    &:before {
      content: url(../../assets/blueEllipses.svg);
      left: -40px;
    }

    &:after {
      content: url(../../assets/redEllipses.svg);
      right: -40px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .heroSection {
    .heroContent {
      display: flex;
      flex-direction: column;
      align-self: center;
    }
  }

  .ideaImage {
    background-image: url("../../assets/Idea.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 40vw;
    height: 420px;
    position: relative;

    &::before {
      content: "";
      height: 100%;
      width: 100%;
      top: -20px;
      left: -20px;
      border: 4px solid #f1e3f3;
      z-index: -1;
      position: absolute;
    }

    &::after {
      content: url("../../assets/Watermark.png");
      position: absolute;
      width: 116px;
      height: 84px;
      bottom: -42px;
      right: -58px;
    }
  }

  .waveText {
    width: 40%;
  }
}

@media screen and (min-width: 1366px){

  .heroSection{
    padding: 100px 80px;
  }
  
   .mbsSytemSection {

    .mbsIconsImg{
      margin: 40px 0 80px 0;
    }
    
     .mbsContent{
       flex-direction: row;
       justify-content: space-evenly;
       align-items: center;
       position: relative;
       z-index: 1;

       .mbsText{
         width: 40%;
       }

       .collegueImage{
         width: 40%;
         height: 720px;

         &:before{
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: #F1E3F3;
          bottom: -20px;
          right: -20px;
          z-index: -1;
         }
       }
     }

     .graphicsHolder {
       bottom: -80px;
     }
   }
}

@media screen and (min-width: 1440px) {
  .heroSection {
    position: relative;

    .heroContent {
      position: relative;
      z-index: 3;
      padding: 120px 20px;
      // width: 50%;
    }

    .heroImage {
      position: absolute;
      right: 0;
      top: -71px;
      width: 40%;
      height: 140%;
      background-image: url(../../assets/heroImage.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .buttonsHero {
      justify-content: flex-end;
    }
  }
}

.Footer{
  display: flex;
  flex-flow: column nowrap;
  background-color: #1E1E24;
  padding: 160px 60px;
  // text-align: center;
  color: #F1E3F3;
}

.contentWrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 80%;
  max-width: 320px;
}

.contactLink {
  text-decoration: none;
  color: #F1E3F3;
  margin: 4px 0;
  padding: 8px;
  transition: 250ms;

  &:hover {
    opacity: 0.8;
  }
}
.Title{
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 30px;
  color: #2A2972;
  text-transform: capitalize;

  @media screen and (min-width: 768px){
    font-size: 60px;
  }
  
  @media screen and (min-width: 1024px){
    font-size: 84px;
  } 
}
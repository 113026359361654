.preFooter {
  display: flex;
  flex-flow: column nowrap;
  background-color: #2B2B33;
  padding: 40px 60px;
}

.contentWrapper {
  width: 80%;
  max-width: 320px;
}

@media screen and (min-width: 768px){
  .contentWrapper{
    display: flex; 
    flex-direction: row;
    max-width: 100%;
  }
}
.disabled {
  text-decoration: line-through;
}